@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip-enter {
  opacity: 0;
  transition: opacity 200ms;
}
.tooltip-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.tooltip-exit {
  opacity: 1;
  transition: opacity 200ms;
}
.tooltip-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

@layer base {
  :root {
    --grey50: #F2F2F2;
    --grey200: #D3D3D3;
  }

  /* Firefox */
  @-moz-document url-prefix() {
    * {
      scrollbar-width: thin;
      scrollbar-color: var(--grey200) var(--grey50);
    }
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  *::-webkit-scrollbar-track {
    border-radius: 8px;
    box-shadow: inset 0 0 10px 10px var(--grey50);
    border: solid 3px transparent;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 8px;
    box-shadow: inset 0 0 10px 10px var(--grey200);
    border: solid 3px transparent;
  }
}

@font-face {
  font-family: 'Quicksand';
  src: url('public/fonts/Quicksand-variable.ttf') format('truetype');
  font-weight: 100 900;
}

@font-face {
  font-family: 'Roboto';
  src: url('public/fonts/Roboto-variable.ttf') format('truetype');
  font-weight: 100 900;
}

@layer utilities {
  .popover {
    margin: 8px;
    border-radius: 8px;
    border: 1px solid #F2F2F2;
    box-shadow: none;
    filter: drop-shadow(5px 5px 25px rgba(0, 0, 0, 0.05));
    @apply bg-white;
  }

  [placeholder]:empty{
    @apply truncate;
  }

  .space-after:after {
    content: ' ' !important;
    white-space: pre!important;
    display: inline-block!important;
  }

  .space-before:before {
    content: ' ' !important;
    white-space: pre!important;
    display: inline-block!important;
  }

  .filter-primary-50 {
    filter: invert(94%) sepia(2%) saturate(654%) hue-rotate(71deg) brightness(106%) contrast(97%)
  }

  .filter-primary-100 {
    filter: invert(96%) sepia(11%) saturate(370%) hue-rotate(60deg) brightness(98%) contrast(91%)
  }

  .filter-primary-200 {
    filter: invert(93%) sepia(14%) saturate(635%) hue-rotate(61deg) brightness(92%) contrast(91%)
  }

  .filter-primary-300 {
    filter: invert(90%) sepia(10%) saturate(1554%) hue-rotate(60deg) brightness(90%) contrast(82%)
  }

  .filter-primary-400 {
    filter: invert(64%) sepia(10%) saturate(1957%) hue-rotate(71deg) brightness(104%) contrast(86%)
  }

  .filter-primary-500 {
    filter: invert(29%) sepia(100%) saturate(1872%) hue-rotate(99deg) brightness(97%) contrast(106%)
  }

  .filter-primary-600 {
    filter: invert(25%) sepia(89%) saturate(1028%) hue-rotate(87deg) brightness(98%) contrast(109%)
  }

  .filter-primary-700 {
    filter: invert(18%) sepia(25%) saturate(4741%) hue-rotate(91deg) brightness(99%) contrast(106%)
  }

  .filter-primary-800 {
    filter: invert(16%) sepia(89%) saturate(1019%) hue-rotate(86deg) brightness(92%) contrast(107%)
  }

  .filter-primary-900 {
    filter: invert(10%) sepia(88%) saturate(1508%) hue-rotate(95deg) brightness(98%) contrast(106%)
  }

  .filter-red-50 {
    filter: invert(89%) sepia(3%) saturate(521%) hue-rotate(307deg) brightness(109%) contrast(98%)
  }

  .filter-red-100 {
    filter: invert(81%) sepia(20%) saturate(294%) hue-rotate(306deg) brightness(107%) contrast(94%)
  }

  .filter-red-200 {
    filter: invert(78%) sepia(3%) saturate(3818%) hue-rotate(305deg) brightness(107%) contrast(90%)
  }

  .filter-red-300 {
    filter: invert(62%) sepia(27%) saturate(604%) hue-rotate(303deg) brightness(96%) contrast(97%)
  }

  .filter-red-400 {
    filter: invert(53%) sepia(12%) saturate(3580%) hue-rotate(308deg) brightness(91%) contrast(92%)
  }

  .filter-red-500 {
    filter: invert(46%) sepia(73%) saturate(5744%) hue-rotate(332deg) brightness(91%) contrast(86%)
  }

  .filter-red-600 {
    filter: invert(22%) sepia(18%) saturate(6206%) hue-rotate(326deg) brightness(92%) contrast(92%)
  }

  .filter-red-700 {
    filter: invert(16%) sepia(19%) saturate(5238%) hue-rotate(324deg) brightness(90%) contrast(95%)
  }

  .filter-red-800 {
    filter: invert(7%) sepia(48%) saturate(3111%) hue-rotate(330deg) brightness(97%) contrast(94%)
  }

  .filter-red-900 {
    filter: invert(5%) sepia(27%) saturate(4783%) hue-rotate(329deg) brightness(96%) contrast(97%)
  }

  .filter-orange-50 {
    filter: invert(86%) sepia(3%) saturate(1033%) hue-rotate(329deg) brightness(114%) contrast(97%)
  }

  .filter-orange-100 {
    filter: invert(82%) sepia(4%) saturate(1982%) hue-rotate(324deg) brightness(118%) contrast(95%)
  }

  .filter-orange-200 {
    filter: invert(85%) sepia(12%) saturate(701%) hue-rotate(342deg) brightness(102%) contrast(88%)
  }

  .filter-orange-300 {
    filter: invert(71%) sepia(63%) saturate(262%) hue-rotate(344deg) brightness(95%) contrast(90%)
  }

  .filter-orange-400 {
    filter: invert(67%) sepia(68%) saturate(351%) hue-rotate(340deg) brightness(91%) contrast(93%)
  }

  .filter-orange-500 {
    filter: invert(71%) sepia(17%) saturate(2576%) hue-rotate(333deg) brightness(92%) contrast(83%)
  }

  .filter-orange-600 {
    filter: invert(48%) sepia(24%) saturate(1268%) hue-rotate(347deg) brightness(97%) contrast(84%)
  }

  .filter-orange-700 {
    filter: invert(34%) sepia(45%) saturate(693%) hue-rotate(347deg) brightness(96%) contrast(89%)
  }

  .filter-orange-800 {
    filter: invert(26%) sepia(20%) saturate(1484%) hue-rotate(348deg) brightness(91%) contrast(92%)
  }

  .filter-orange-900 {
    filter: invert(12%) sepia(8%) saturate(5509%) hue-rotate(352deg) brightness(100%) contrast(87%)
  }

  .filter-yellow-50 {
    filter: invert(92%) sepia(24%) saturate(544%) hue-rotate(302deg) brightness(114%) contrast(98%)
  }

  .filter-yellow-100 {
    filter: invert(87%) sepia(24%) saturate(213%) hue-rotate(356deg) brightness(106%) contrast(95%)
  }

  .filter-yellow-200 {
    filter: invert(90%) sepia(16%) saturate(699%) hue-rotate(339deg) brightness(105%) contrast(93%)
  }

  .filter-yellow-300 {
    filter: invert(94%) sepia(13%) saturate(1631%) hue-rotate(326deg) brightness(104%) contrast(89%)
  }

  .filter-yellow-400 {
    filter: invert(95%) sepia(72%) saturate(848%) hue-rotate(318deg) brightness(93%) contrast(99%)
  }

  .filter-yellow-500 {
    filter: invert(96%) sepia(73%) saturate(2135%) hue-rotate(319deg) brightness(96%) contrast(88%)
  }

  .filter-yellow-600 {
    filter: invert(79%) sepia(92%) saturate(1080%) hue-rotate(324deg) brightness(83%) contrast(81%)
  }

  .filter-yellow-700 {
    filter: invert(45%) sepia(59%) saturate(465%) hue-rotate(5deg) brightness(94%) contrast(87%)
  }

  .filter-yellow-800 {
    filter: invert(30%) sepia(50%) saturate(566%) hue-rotate(6deg) brightness(95%) contrast(88%)
  }

  .filter-yellow-900 {
    filter: invert(19%) sepia(17%) saturate(1647%) hue-rotate(5deg) brightness(95%) contrast(90%)
  }

  .filter-green-50 {
    filter: invert(94%) sepia(8%) saturate(288%) hue-rotate(62deg) brightness(106%) contrast(97%)
  }

  .filter-green-100 {
    filter: invert(96%) sepia(9%) saturate(486%) hue-rotate(47deg) brightness(102%) contrast(93%)
  }

  .filter-green-200 {
    filter: invert(92%) sepia(20%) saturate(595%) hue-rotate(43deg) brightness(96%) contrast(90%)
  }

  .filter-green-300 {
    filter: invert(88%) sepia(32%) saturate(605%) hue-rotate(43deg) brightness(90%) contrast(90%)
  }

  .filter-green-400 {
    filter: invert(78%) sepia(15%) saturate(1509%) hue-rotate(53deg) brightness(95%) contrast(88%)
  }

  .filter-green-500 {
    filter: invert(56%) sepia(33%) saturate(988%) hue-rotate(56deg) brightness(104%) contrast(91%)
  }

  .filter-green-600 {
    filter: invert(71%) sepia(39%) saturate(6736%) hue-rotate(63deg) brightness(91%) contrast(80%)
  }

  .filter-green-700 {
    filter: invert(32%) sepia(39%) saturate(6604%) hue-rotate(75deg) brightness(96%) contrast(84%)
  }

  .filter-green-800 {
    filter: invert(21%) sepia(42%) saturate(2047%) hue-rotate(68deg) brightness(101%) contrast(89%)
  }

  .filter-green-900 {
    filter: invert(20%) sepia(17%) saturate(1783%) hue-rotate(57deg) brightness(94%) contrast(101%)
  }

  .filter-blue1-50 {
    filter: invert(100%) sepia(84%) saturate(1558%) hue-rotate(179deg) brightness(110%) contrast(96%)
  }

  .filter-blue1-100 {
    filter: invert(86%) sepia(24%) saturate(212%) hue-rotate(183deg) brightness(104%) contrast(91%)
  }

  .filter-blue1-200 {
    filter: invert(88%) sepia(6%) saturate(1268%) hue-rotate(175deg) brightness(96%) contrast(91%)
  }

  .filter-blue1-300 {
    filter: invert(78%) sepia(15%) saturate(950%) hue-rotate(174deg) brightness(90%) contrast(92%)
  }

  .filter-blue1-400 {
    filter: invert(65%) sepia(17%) saturate(1344%) hue-rotate(168deg) brightness(86%) contrast(85%)
  }

  .filter-blue1-500 {
    filter: invert(52%) sepia(63%) saturate(439%) hue-rotate(165deg) brightness(83%) contrast(101%)
  }

  .filter-blue1-600 {
    filter: invert(33%) sepia(32%) saturate(1015%) hue-rotate(166deg) brightness(90%) contrast(87%)
  }

  .filter-blue1-700 {
    filter: invert(25%) sepia(18%) saturate(1422%) hue-rotate(165deg) brightness(92%) contrast(99%)
  }

  .filter-blue1-800 {
    filter: invert(12%) sepia(12%) saturate(2619%) hue-rotate(165deg) brightness(97%) contrast(94%)
  }

  .filter-blue1-900 {
    filter: invert(7%) sepia(6%) saturate(6587%) hue-rotate(168deg) brightness(95%) contrast(93%)
  }

  .filter-purple-50 {
    filter: invert(95%) sepia(30%) saturate(157%) hue-rotate(182deg) brightness(99%) contrast(95%)
  }

  .filter-purple-100 {
    filter: invert(100%) sepia(49%) saturate(966%) hue-rotate(181deg) brightness(100%) contrast(87%)
  }

  .filter-purple-200 {
    filter: invert(93%) sepia(18%) saturate(457%) hue-rotate(194deg) brightness(92%) contrast(92%)
  }

  .filter-purple-300 {
    filter: invert(78%) sepia(18%) saturate(269%) hue-rotate(217deg) brightness(93%) contrast(84%)
  }

  .filter-purple-400 {
    filter: invert(68%) sepia(8%) saturate(996%) hue-rotate(216deg) brightness(88%) contrast(86%)
  }

  .filter-purple-500 {
    filter: invert(61%) sepia(8%) saturate(1412%) hue-rotate(216deg) brightness(85%) contrast(89%)
  }

  .filter-purple-600 {
    filter: invert(37%) sepia(13%) saturate(905%) hue-rotate(216deg) brightness(93%) contrast(88%)
  }

  .filter-purple-700 {
    filter: invert(25%) sepia(12%) saturate(973%) hue-rotate(215deg) brightness(92%) contrast(89%)
  }

  .filter-purple-800 {
    filter: invert(12%) sepia(8%) saturate(1767%) hue-rotate(216deg) brightness(98%) contrast(91%)
  }

  .filter-purple-900 {
    filter: invert(7%) sepia(5%) saturate(3610%) hue-rotate(212deg) brightness(90%) contrast(90%)
  }

  .filter-blue2-50 {
    filter: invert(89%) sepia(2%) saturate(219%) hue-rotate(201deg) brightness(109%) contrast(94%)
  }

  .filter-blue2-100 {
    filter: invert(85%) sepia(9%) saturate(128%) hue-rotate(208deg) brightness(101%) contrast(90%)
  }

  .filter-blue2-200 {
    filter: invert(84%) sepia(11%) saturate(258%) hue-rotate(208deg) brightness(80%) contrast(89%)
  }

  .filter-blue2-300 {
    filter: invert(52%) sepia(15%) saturate(476%) hue-rotate(208deg) brightness(86%) contrast(89%)
  }

  .filter-blue2-400 {
    filter: invert(28%) sepia(25%) saturate(643%) hue-rotate(208deg) brightness(92%) contrast(87%)
  }

  .filter-blue2-500 {
    filter: invert(14%) sepia(9%) saturate(3644%) hue-rotate(208deg) brightness(96%) contrast(90%)
  }

  .filter-blue2-600 {
    filter: invert(11%) sepia(10%) saturate(3390%) hue-rotate(208deg) brightness(95%) contrast(91%)
  }

  .filter-blue2-700 {
    filter: invert(9%) sepia(27%) saturate(1159%) hue-rotate(208deg) brightness(96%) contrast(95%)
  }

  .filter-blue2-800 {
    filter: invert(7%) sepia(18%) saturate(1669%) hue-rotate(210deg) brightness(91%) contrast(97%)
  }

  .filter-blue2-900 {
    filter: invert(4%) sepia(4%) saturate(7401%) hue-rotate(211deg) brightness(99%) contrast(99%)
  }

  .filter-grey-0 {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(320deg) brightness(100%) contrast(100%)
  }

  .filter-grey-50 {
    filter: invert(100%) sepia(3%) saturate(85%) hue-rotate(16deg) brightness(112%) contrast(90%)
  }

  .filter-grey-100 {
    filter: invert(99%) sepia(8%) saturate(181%) hue-rotate(69deg) brightness(113%) contrast(80%)
  }

  .filter-grey-200 {
    filter: invert(99%) sepia(0%) saturate(515%) hue-rotate(162deg) brightness(89%) contrast(86%)
  }

  .filter-grey-300 {
    filter: invert(85%) sepia(0%) saturate(1494%) hue-rotate(179deg) brightness(86%) contrast(87%)
  }

  .filter-grey-400 {
    filter: invert(65%) sepia(0%) saturate(10%) hue-rotate(158deg) brightness(89%) contrast(94%)
  }

  .filter-grey-500 {
    filter: invert(52%) sepia(0%) saturate(193%) hue-rotate(169deg) brightness(97%) contrast(83%)
  }

  .filter-grey-600 {
    filter: invert(31%) sepia(0%) saturate(121%) hue-rotate(207deg) brightness(96%) contrast(98%)
  }

  .filter-grey-700 {
    filter: invert(19%) sepia(0%) saturate(1136%) hue-rotate(239deg) brightness(75%) contrast(77%)
  }

  .filter-grey-800 {
    filter: invert(6%) sepia(55%) saturate(0%) hue-rotate(158deg) brightness(97%) contrast(86%)
  }

  .filter-grey-900 {
    filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(164deg) brightness(95%) contrast(103%)
  }
  .w-icon-16 {
    width: 16px;
  }
  .w-icon-20 {
    width: 20px;
  }
  .w-icon-24 {
    width: 24px;
  }
  .h-icon-16 {
    height: 16px;
  }
  .h-icon-20 {
    height: 20px;
  }
  .h-icon-24 {
    height: 24px;
  }
}
